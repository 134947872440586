.SwipeableListItem_content__3wbMa {
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
}

.SwipeableListItem_contentReturn__Kx-Al {
  display: flex;
  justify-content: center;
}
.SwipeableListItem_swipeableListItem__3Tgya {
  max-height: unset;
}
